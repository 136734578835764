import React, {useCallback, useMemo, useRef, useEffect} from 'react'
import {getRecord, type RecordResult} from 'transport/v3/records'
import {wait} from 'utils/timeout'
import Context, {type Listener} from './context'

type Props = {
  children: React.ReactNode
}

const NOOP = () => {} // eslint-disable-line

const PollingRecordsProvider: React.FC<Props> = (props) => {
  const abortController = useRef<AbortController>()
  const listeners = useRef<Map<Listener, Record<number, string>>>(new Map())

  const startPolling = useCallback(async (): Promise<void> => {
    const {signal} = (abortController.current = new AbortController())

    while (true) {
      try {
        await wait(5e3, signal)

        const data: Record<number, RecordResult> = {}
        let items: Record<number, string> = {}

        Array.from(listeners.current).forEach(([_, objMap]) => {
          items = {...items, ...objMap}
        })

        await Promise.all(
          Object.entries(items).map(([id, accountCode]) =>
            getRecord({id: Number(id), accountCode}, signal).then(
              (response) => {
                data[Number(id)] = response.result
              },
              NOOP
            )
          )
        )

        Array.from(listeners.current).forEach(([listener, objMap]) => {
          const items = Object.keys(objMap)
            .map((id) => data[Number(id)])
            .filter(Boolean)

          if (items.length) {
            listener(items)
          }
        })
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          throw err
        } else {
          break
        }
      }
    }
  }, [])

  const stopPolling = useCallback(() => {
    abortController.current?.abort()
    abortController.current = undefined
  }, [])

  const subscribe = useCallback(
    (listener: Listener, ...items: [number, string][]): void => {
      if (!items.length) return

      const listenerItems = listeners.current.get(listener) ?? {}

      items.forEach(([id, accountCode]) => {
        listenerItems[id] = accountCode
      })

      listeners.current.set(listener, listenerItems)

      if (!abortController.current) startPolling()
    },
    [startPolling]
  )

  const unsubscribe = useCallback(
    (listener: Listener, ...ids: number[]): void => {
      const listenerItems = listeners.current.get(listener)
      if (!listenerItems) return

      ids.forEach((id) => {
        delete listenerItems[id]
      })

      if (ids.length === 0 || Object.keys(listenerItems).length === 0) {
        listeners.current.delete(listener)
      }

      if (listeners.current.size === 0) stopPolling()
    },
    []
  )

  const contextValue = useMemo(
    () => ({
      subscribe,
      unsubscribe
    }),
    [subscribe]
  )

  useEffect(() => {
    return () => stopPolling()
  }, [])

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  )
}

export default PollingRecordsProvider
