import {createContext} from 'react'
import {type RecordResult} from 'transport/v3/records'

export type Listener = (data: RecordResult[]) => any

export type ContextType = {
  subscribe: (listener: Listener, ...items: [number, string][]) => void
  unsubscribe: (listener: Listener, ...ids: number[]) => void
}

const DEFAULT_CONTEXT: ContextType = {
  subscribe: () => {}, // eslint-disable-line
  unsubscribe: () => {} // eslint-disable-line
}

export default createContext<ContextType>(DEFAULT_CONTEXT)
