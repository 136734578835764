import React, {useCallback, useState, useMemo} from 'react'
import classnames from 'classnames'
import {Scrollbar} from 'react-scrollbars-custom'
import withUploadRecords, {
  type WithUploadRecordsProps
} from 'hoc/withUploadRecords'
import {type Item as ItemType} from 'containers/UploadRecordsProvider/context'
import ButtonWithArrow from 'components/ButtonWithArrow'
import Item from './Item'
import styles from './styles.css'

type Props = WithUploadRecordsProps & {
  className?: string
}

const LIST_LIMIT = 3

const STYLES = {
  position: undefined,
  top: undefined,
  bottom: undefined,
  display: undefined,
  width: undefined,
  height: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  paddingLeft: undefined,
  paddingRight: undefined,
  borderRadius: undefined,
  background: undefined
}

const STATUS_ORDER: Map<ItemType['status'], number> = new Map([
  ['IN_PROGRESS', -1],
  ['FAILED', 1],
  ['ABORTED', 2],
  ['COMPLETED', 3]
])

const UPLOADER_STATUS_ORDER: Map<ItemType['uploaderStatus'], number> = new Map([
  ['ERROR', -1],
  ['READY', 1]
])

const RecordsUploaderList: React.FC<Props> = ({
  className,
  uploadRecords: {list, data, abortUploading, retryUploading, deleteUploading}
}) => {
  const [isExpanded, setState] = useState(false)

  const toggleExpandState = useCallback((): void => {
    setState((isExpanded) => !isExpanded)
  }, [])

  const sortedList = useMemo(
    () =>
      list.sort((a, b) => {
        const item1 = data[a]
        const item2 = data[b]
        const status1 = item1.status || null
        const status2 = item2.status || null

        if (status1 !== status2)
          return (
            (STATUS_ORDER.get(status1) ?? 0) - (STATUS_ORDER.get(status2) ?? 0)
          )

        if (status1 === 'IN_PROGRESS') return item1.progress - item2.progress

        if (status1 === 'COMPLETED')
          return (
            (UPLOADER_STATUS_ORDER.get(item1.uploaderStatus) ?? 0) -
            (UPLOADER_STATUS_ORDER.get(item2.uploaderStatus) ?? 0)
          )

        return 0
      }),
    [list, data]
  )

  const withButton = sortedList.length > LIST_LIMIT
  const resultList =
    withButton && !isExpanded ? sortedList.slice(0, LIST_LIMIT) : list

  return (
    (resultList.length > 0 && (
      <div className={classnames(className, styles.root)}>
        <Scrollbar
          style={{maxHeight: 305}}
          translateContentSizeYToHolder
          contentProps={{
            renderer: ({elementRef, style, ...props}) => (
              <ul
                {...props}
                ref={elementRef}
                className={styles.list}
                style={{...style, ...STYLES}}
              />
            )
          }}
          wrapperProps={{
            renderer: ({elementRef, style, ...props}) => (
              <div
                {...props}
                ref={elementRef}
                className={styles.scrollbar}
                style={{...style, ...STYLES}}
              />
            )
          }}
          scrollerProps={{
            renderer: ({elementRef, style, ...props}) => (
              <div
                {...props}
                ref={elementRef}
                className={styles.scrollbarInner}
                style={{...style, ...STYLES}}
              />
            )
          }}
          trackYProps={{
            renderer: ({elementRef, style, ...props}) => (
              <div
                {...props}
                ref={elementRef}
                className={styles.scrollbarTrack}
                style={{...style, ...STYLES}}
              />
            )
          }}
          thumbYProps={{
            renderer: ({elementRef, style, ...props}) => (
              <div
                {...props}
                ref={elementRef}
                className={styles.scrollbarThumb}
                style={{...style, ...STYLES, top: style?.top}}
              />
            )
          }}>
          {resultList.map((itemId) => (
            <Item
              key={itemId}
              item={data[itemId]}
              abortUploading={abortUploading}
              retryUploading={retryUploading}
              deleteUploading={deleteUploading}
            />
          ))}
        </Scrollbar>

        {withButton && (
          <ButtonWithArrow
            className={styles.toggleExpand}
            onClick={toggleExpandState}
            invertArrow={isExpanded}>
            {isExpanded
              ? 'Свернуть список'
              : `Загружается ещё ${list.length - LIST_LIMIT} видео`}
          </ButtonWithArrow>
        )}
      </div>
    )) ||
    null
  )
}

export default withUploadRecords(RecordsUploaderList)
