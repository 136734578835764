import React, {Component} from 'react'
import {openDB, type IDBPDatabase, type DBSchema} from 'idb'
import {wait} from 'utils/timeout'
import {completeUploading} from 'transport/v3/uploader'
import {postRecord, uploadFile, deleteRecord} from 'transport/records'
import {type RecordResult, getRecord, updateRecord} from 'transport/v3/records'
import {initializeMasterWorker} from 'workers'
import withPollingRecords, {
  type WithPollingRecordsProps
} from 'hoc/withPollingRecords'
import AppContext, {
  type ContextType as AppContextType
} from 'containers/App/context'
import UploadProviderContext, {
  type State,
  type Item,
  type ContextType,
  DEFAULT_STATE
} from './context'
import {getUpdatedData, ACCEPTED_TYPES, sendFile, getEmptyFile} from './utils'
import {apiErrorCounter} from 'utils/prometheus'

type Props = WithPollingRecordsProps & {
  children?: React.ReactNode
}

interface UploaderDBSchema extends DBSchema {
  States: {
    value: State
    key: number
  }
}

const API_ERORR_CODES = [400, 502, 504]
const REQUEST_ERROR_RETRY_TIMEOUT = 15e3
const UPLOAD_COUNT_LIMIT = 3

type SetStateArgs = Parameters<Component<Props, State>['setState']>

class UploadRecordsProvider extends Component<Props, State> {
  static contextType = AppContext
  context: AppContextType

  state = DEFAULT_STATE

  abortControllers: Map<string, AbortController> = new Map() // itemId, AbortController
  db?: IDBPDatabase<UploaderDBSchema>
  master = false

  worker = initializeMasterWorker('recordsUploader')

  setSharedState = (
    state: SetStateArgs[0],
    callback?: SetStateArgs[1],
    putIntoDb = true
  ): void => {
    this.setState(
      state,
      this.master
        ? () => {
            this.worker.port.postMessage({
              actionType: 'updateState',
              payload: {
                state: this.state
              }
            })

            if (putIntoDb) {
              this.db?.put('States', this.state, this.context.user.id ?? -1)
            }

            callback?.()
          }
        : callback
    )
  }

  componentDidMount(): void {
    this.worker.port.addEventListener('message', this.onWorkerMessage)
    this.worker.port.start()
    window.addEventListener('beforeunload', this.disconnect)
  }

  unmounted = false

  componentWillUnmount(): void {
    this.unmounted = true
    this.disconnect()
    this.abortControllers.forEach((controller) => controller.abort())
    this.unsubscribePolling()
  }

  subscribePolling = (...items: [number, string][]): void => {
    this.props.pollingRecords.subscribe(this.handleRecordsPolling, ...items)
  }

  unsubscribePolling = (...ids: number[]): void => {
    this.props.pollingRecords.unsubscribe(this.handleRecordsPolling, ...ids)
  }

  disconnect = (): void => {
    window.removeEventListener('beforeunload', this.disconnect)
    this.worker.port.removeEventListener('message', this.onWorkerMessage)
    this.worker.port.postMessage({actionType: 'disconnect'})
    this.worker.port.close()
  }

  onWorkerMessage = (event: MessageEvent<any>): void => {
    const {actionType, payload} = event.data

    switch (actionType) {
      case 'initialize':
        this.initialize(!!payload?.initial, !!payload?.master, payload?.state)
        break
      case 'updateState':
        if (payload?.state) this.setState(payload.state)
        break
      case 'addItems':
        if (payload?.items) this.addItems(payload.items)
        break
      case 'deleteItem':
        if (payload?.itemId) this.deleteItem(payload.itemId)
        break
      case 'abortItem':
        if (payload?.itemId) this.abortUploading(payload.itemId)
        break
      case 'retryItem':
        if (payload?.itemId) this.retryUploading(payload.itemId)
        break
      case 'error':
        if (payload?.error) this.context.handleException(payload.error)
        break
    }
  }

  initialize = async (
    initial: boolean,
    master: boolean,
    state?: State
  ): Promise<void> => {
    this.master = master

    if (master) {
      this.db = await openDB<UploaderDBSchema>('Uploader', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('States'))
            db.createObjectStore('States')
        }
      })

      if (initial) {
        // initialize master
        const state = await this.db.get('States', this.context.user.id ?? -1)

        if (state) {
          // Меняем статус у незагруженных видео
          Object.values(state.data).map((item) => {
            if (!item.status || item.status === 'IN_PROGRESS')
              item.status = 'ABORTED'
          })

          // Очищаем очередь загрузок
          state.queue = []
          // Не сохраняем state повторно в DB
          this.setSharedState(state, undefined, false)
        }
      } else {
        // reinitialize master
        const {data, list} = this.state
        const unprocessedItems: [number, string][] = []

        list.forEach((id) => {
          const item = data[id]
          if (!item) return

          // Восстанавливаем загрузку
          if (item.status === 'IN_PROGRESS') {
            this.sendFile(id)
          }

          if (
            item.status === 'COMPLETED' &&
            !(item.completedAt && item.uploaderStatus === 'READY')
          ) {
            const recordId = item.recordId ?? item.replacedRecordId

            if (recordId) unprocessedItems.push([recordId, item.accountCode])
          }
        })

        // Восстанавливаем получение обновлений
        if (unprocessedItems.length) this.subscribePolling(...unprocessedItems)
      }
    } else if (state) {
      // initialize slave
      this.setState(state)
    }
  }

  handleRecordsPolling = (items: RecordResult[]): void => {
    const {data, itemIds} = this.state
    const unsubscribeIds: number[] = []

    items.forEach((pollingItem) => {
      const itemId = itemIds[pollingItem.id]
      if (itemId == null) return
      const item = data[itemId]
      if (item == null) return

      const {currentState: uploaderStatus, currentStateUpdatedAt} = pollingItem
      let completedAt: Date | null = null

      if (uploaderStatus !== item.uploaderStatus) {
        if (uploaderStatus === 'READY' && currentStateUpdatedAt) {
          unsubscribeIds.push(pollingItem.id)
          completedAt = new Date(currentStateUpdatedAt)
        }

        this.setSharedState(
          (state) =>
            ({
              data: getUpdatedData(state, itemId, {uploaderStatus, completedAt})
            }) as State
        )
      }
    })

    if (unsubscribeIds.length) this.unsubscribePolling(...unsubscribeIds)
  }

  uploadFiles: ContextType['uploadFiles'] = async ({
    files,
    accountName,
    accountCode,
    apiOrigin,
    replacedRecordId,
    soundDisabled = false
  }) => {
    if (replacedRecordId != null && files.length > 1) {
      this.context.openSnackbar({
        message: 'Необходимо выбрать 1 файл',
        type: 'error'
      })

      return
    }

    for (const file of files) {
      const [type, subtype] = file.type.split('/')
      const isMxf =
        file.type === 'application/mxf' ||
        (file.type === '' && file.name.endsWith('.mxf'))
      const isMkv = file.type === '' && file.name.endsWith('.mkv')
      const isValidFormat =
        isMxf ||
        isMkv ||
        (type === 'video' && subtype && ACCEPTED_TYPES.includes(subtype))

      if (!isValidFormat) {
        this.context.openSnackbar({
          message: `Неверный формат файла ${file.name}`,
          type: 'error'
        })

        return
      }
    }

    if (replacedRecordId != null) {
      this.context.openSnackbar({
        type: 'success',
        autoCloseDuration: 5e3,
        message:
          'Замена видеофайла началась в фоновом режиме. Обновите страницу через несколько минут для просмотра'
      })
    }

    this.addItems(
      Array.from(files, (file, index) => ({
        id: `${Date.now()}.${index}`,
        progress: 0,
        partNumbers: [],
        status: null,
        uploaderStatus: null,
        completedAt: null,
        uploadId: null,
        recordId: null,
        accountName,
        accountCode,
        apiOrigin,
        replacedRecordId: replacedRecordId ?? null,
        file,
        soundDisabled
      }))
    )
  }

  addItems = (items: Item[]): void => {
    if (this.master) {
      const newItemsList = items.map((item) => item.id)
      const newItemsData = Object.fromEntries(
        items.map((item) => [item.id, item])
      )

      this.setSharedState(
        (state) =>
          ({
            data: {...newItemsData, ...state.data},
            list: [...newItemsList, ...state.list],
            queue: [...state.queue, ...newItemsList]
          }) as State,
        this.uploadNext
      )
    } else {
      this.worker.port.postMessage({
        actionType: 'addItems',
        target: 'master',
        payload: {
          items
        }
      })
    }
  }

  async sendFile(itemId: string): Promise<void> {
    const item = this.state.data[itemId]
    if (!item) return

    const {
      replacedRecordId,
      file,
      soundDisabled,
      apiOrigin: origin,
      accountCode
    } = item
    let {uploadId, partNumbers, progress} = item

    const abortController = new AbortController()
    const {signal} = abortController

    this.abortControllers.set(itemId, abortController)
    this.setSharedState(
      (state) =>
        ({
          data: getUpdatedData(state, itemId, {
            status: 'IN_PROGRESS'
          }),
          queue: state.queue.filter((i) => i !== itemId)
        }) as State
    )

    try {
      let recordId = replacedRecordId ?? item.recordId

      if (!uploadId) {
        const newData: Partial<Item> = {}
        const fileName = file.name

        const recordPayload = {
          original_filename: fileName,
          upload_file_size: file.size,
          is_mute: soundDisabled
        }

        while (true) {
          try {
            if (replacedRecordId) {
              const response = await uploadFile(
                {
                  ...recordPayload,
                  id: replacedRecordId
                },
                {
                  signal,
                  origin
                }
              )

              uploadId = response.data.upload_id
              recordId = replacedRecordId
            } else {
              const lastDotIndex = fileName.lastIndexOf('.')
              const {data} = await postRecord(
                {
                  ...recordPayload,
                  name:
                    (lastDotIndex > -1 && file.name.slice(0, lastDotIndex)) ||
                    fileName
                },
                {
                  signal,
                  origin
                }
              )

              uploadId = data.upload_id
              recordId = data.id
              newData.recordId = data.id
            }

            break
          } catch (err: any) {
            if (err.code === 0) {
              await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
            } else {
              throw err
            }
          }
        }

        newData.uploadId = uploadId

        this.setSharedState(
          (state) =>
            ({
              data: getUpdatedData(state, itemId, newData),
              itemIds: recordId
                ? {
                    ...state.itemIds,
                    [recordId]: itemId
                  }
                : state.itemIds
            }) as State
        )
      }

      const timeouts = [1e3, 5e3, 10e3]

      while (true) {
        try {
          partNumbers = await sendFile({
            uploadId,
            file,
            partNumbers,
            onProgress: (changedProgress) => {
              // Отдельно храним progress, чтобы обновлять его в DB только при onChunkLoaded
              progress = changedProgress

              this.setSharedState(
                (state) =>
                  ({
                    data: getUpdatedData(state, itemId, {progress})
                  }) as State,
                undefined,
                false // Не обновляем progress в DB при onProgress
              )
            },
            onChunkLoaded: (partNumbers) => {
              this.setSharedState(
                (state) =>
                  ({
                    data: getUpdatedData(state, itemId, {
                      partNumbers,
                      progress
                    })
                  }) as State
              )
            },
            signal
          })
          break
        } catch (err: any) {
          if (err.name !== 'AbortError') {
            apiErrorCounter.inc(1, {
              endpoint: '/api/uploader/upload',
              errorCode: err.code != null ? String(err.code) : 'unknown'
            })
          }

          if (err.code === 0) {
            await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
          } else if (
            timeouts.length > 0 &&
            API_ERORR_CODES.includes(err.code)
          ) {
            await wait(timeouts.shift() as number, signal)
          } else {
            throw err
          }
        }
      }

      while (true) {
        try {
          await completeUploading(
            {
              uploadUuid: uploadId,
              partNumbers
            },
            signal
          )
          break
        } catch (err: any) {
          if (err.code === 0) {
            await wait(REQUEST_ERROR_RETRY_TIMEOUT, signal)
          } else {
            throw err
          }
        }
      }

      this.setSharedState(
        (state) =>
          ({
            data: getUpdatedData(state, itemId, {
              file: getEmptyFile(state.data[itemId]?.file.name ?? ''),
              status: 'COMPLETED'
            })
          }) as State,
        this.master ? this.uploadNext : undefined
      )

      if (recordId) {
        this.subscribePolling([recordId, accountCode])
      }

      if (replacedRecordId) {
        try {
          const {result} = await getRecord({
            id: replacedRecordId,
            accountCode
          })

          if (result.frontSettings?.episodes) {
            delete result.frontSettings.episodes
            await updateRecord(result)
          }
        } catch {}
      }
    } catch (error: any) {
      if (this.unmounted) return
      const aborted = error.name === 'AbortError'

      this.setSharedState(
        (state) =>
          ({
            data: getUpdatedData(state, itemId, {
              status: aborted ? 'ABORTED' : 'FAILED'
            })
          }) as State,
        this.master ? this.uploadNext : undefined
      )

      if (!aborted) {
        this.worker.port.postMessage({actionType: 'error', payload: {error}})
        this.context.handleException(error)
        console.error(error)
      }
    } finally {
      this.abortControllers.delete(itemId)
    }
  }

  abortUploading: ContextType['abortUploading'] = (itemId) => {
    if (this.master) {
      this.abortControllers.get(itemId)?.abort()
    } else {
      this.worker.port.postMessage({
        actionType: 'abortItem',
        target: 'master',
        payload: {
          itemId
        }
      })
    }
  }

  retryUploading: ContextType['retryUploading'] = async (itemId) => {
    if (this.master) {
      this.setSharedState(
        (state) =>
          ({
            data: getUpdatedData(state, itemId, {
              status: null
            }),
            queue: state.queue.concat(itemId)
          }) as State,
        this.uploadNext
      )
    } else {
      this.worker.port.postMessage({
        actionType: 'retryItem',
        target: 'master',
        payload: {
          itemId
        }
      })
    }
  }

  uploadNext = (): void => {
    const {queue, data, list} = this.state
    const inProgressCount = list.filter(
      (itemId) => data[itemId]?.status === 'IN_PROGRESS'
    ).length

    const shouldUploadCount = UPLOAD_COUNT_LIMIT - inProgressCount

    if (shouldUploadCount > 0) {
      queue.slice(0, shouldUploadCount).forEach((itemId) => {
        this.sendFile(itemId)
      })
    }
  }

  deleteUploading: ContextType['deleteUploading'] = async (
    itemId,
    shouldDeleteRecord
  ) => {
    const {recordId, apiOrigin: origin} = this.state.data[itemId]

    if (recordId && shouldDeleteRecord) {
      try {
        await deleteRecord(recordId, {origin})
      } catch (err: any) {
        this.context.handleException(err)
        throw err
      }
    }

    this.deleteItem(itemId)
  }

  deleteItem = (itemId: string): void => {
    if (this.master) {
      const {recordId, replacedRecordId} = this.state.data[itemId]
      const id = recordId ?? replacedRecordId

      if (id) {
        this.unsubscribePolling(id)
      }

      this.abortControllers.delete(itemId)
      this.setSharedState((state) => {
        const data = {...state.data}
        let itemIds = state.itemIds

        delete data[itemId]

        if (id) {
          itemIds = {...itemIds}
          delete itemIds[id]
        }

        return {
          list: state.list.filter((id) => id !== itemId),
          data,
          queue: state.queue.filter((id) => id !== itemId),
          itemIds
        } as State
      })
    } else {
      this.worker.port.postMessage({
        actionType: 'deleteItem',
        target: 'master',
        payload: {
          itemId
        }
      })
    }
  }

  getItemByRecordId: ContextType['getItemByRecordId'] = (recordId) =>
    this.state.data[this.state.itemIds[recordId] ?? -1]

  render(): JSX.Element {
    return (
      <UploadProviderContext.Provider
        value={{
          ...this.state,
          uploadFiles: this.uploadFiles,
          abortUploading: this.abortUploading,
          retryUploading: this.retryUploading,
          deleteUploading: this.deleteUploading,
          getItemByRecordId: this.getItemByRecordId
        }}>
        {this.props.children}
      </UploadProviderContext.Provider>
    )
  }
}

export default withPollingRecords(UploadRecordsProvider)
